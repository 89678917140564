<template>
    <div
        v-if="usuario"
        class="
            d-flex
            align-items-center                    
            bg-primary
        "
    >        
        <div class="lh-1 mt-2 bg-white w-100 py-4 px-4 rounded-top-5 rounded-top">
            <slot>
                <strong class="fs-5">
                    Olá, {{ usuario.nome }}<br />
                </strong>
                <small>{{ usuario.email }}</small>
            </slot>
        </div>
    </div>
</template>
<script lang="ts" setup>
import Logo from '../assets/logo.svg'
import { current_user as usuario } from '@/store/auth';
</script>
<style lang="less" scoped>
    svg.logo {
        font-size: 2rem;
        color: white;
    }

    .rounded-top-5 {
        --bs-border-radius: var(--bs-border-radius-2xl);
    }
</style>